<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show">Материалы партнеров
            <router-link to="/">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_226_668)">
                  <circle cx="19" cy="18" r="18" transform="rotate(-90 19 18)" fill="#59B980"/>
                </g>
                <path
                    d="M15.779 27.0592L16.0039 27.1096L16.1883 26.9713L24.3651 20.8412L24.3659 20.8406C25.6264 19.8918 26.3574 18.7758 26.3574 17.5713C26.3574 16.3668 25.6264 15.2508 24.3659 14.302L24.3651 14.3014L16.1883 8.17123L16.0039 8.03298L15.779 8.0834L15.4623 8.15441L14.4039 8.3917L15.2718 9.04235L23.4482 15.1723C24.5481 15.9984 24.9975 16.8291 24.9975 17.5713C24.9975 18.3135 24.5482 19.1441 23.4484 19.9702C23.4483 19.9702 23.4482 19.9703 23.4482 19.9703L15.2718 26.1002L14.4039 26.7509L15.4623 26.9882L15.779 27.0592Z"
                    fill="white" stroke="white"/>
                <defs>
                  <filter id="filter0_d_226_668" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_226_668"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_226_668" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </router-link>
          </h2>
        </div>
      </div>
      <div class="row pt-4 d-flex flex-column-reverse flex-lg-row">
        <div class="col-12 col-lg-3">
          <div class="pt-2 pt-lg-0">
            <rasp-slider></rasp-slider>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row d-flex align-items-stretch">
            <div class="col-12 col-md-6 col-lg-4 pb-4 d-flex align-items-stretch" v-for="partner in getPartners"
                 :key="partner.id">
              <div class="partner">
                <div class="partner__image">
                  <img :src="partner.avatar" :alt="partner.name">
                </div>
                <div class="partner__files d-flex flex-wrap pt-4">
                  <div class="partner__file pb-2" v-for="file in partner.PartnerFiles" :key="file.id">
                    <a :href="file.link" target="_blank">{{ file.name }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import RaspSlider from "../components/components/RaspSlider";

export default {
  name: "Materials",
  components: {RaspSlider},
  data() {
    return {
      partners: [{
        "id": 4,
        "name": "Aerodisk Blue",
        "avatar": "/images/AERODISK.png",
        "link": "https://aerodisk.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 1,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:37:09.000Z",
        "updatedAt": "2022-04-05T06:46:27.000Z",
        "PartnerFiles": [{
          "id": 19,
          "link": "https://drive.google.com/file/d/1XedVs5o_bcx1WV5Rv_LvTUUZCnKtpKpo/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T20:42:03.000Z",
          "updatedAt": "2022-04-17T20:42:03.000Z",
          "partner_id": 4
        }]
      }, {
        "id": 11,
        "name": "Gblobal-Digital",
        "avatar": "/images/Global_CIO_1.png",
        "link": "https://globalcio.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 1,
        "hideToIndex": false,
        "createdAt": "2022-04-03T16:26:45.000Z",
        "updatedAt": "2022-04-05T06:46:59.000Z",
        "PartnerFiles": []
      }, {
        "id": 14,
        "name": "ICL Techno",
        "avatar": "/images/ICL.png",
        "link": "/partners/icl-techno",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 1,
        "hideToIndex": false,
        "createdAt": "2022-04-04T06:24:05.000Z",
        "updatedAt": "2022-04-20T16:59:42.000Z",
        "PartnerFiles": [{
          "id": 5,
          "link": "https://drive.google.com/file/d/1LvoDhJrSmkTufx1Mdnsuv01bRXEFAYY7/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T11:28:45.000Z",
          "updatedAt": "2022-04-17T11:28:45.000Z",
          "partner_id": 14
        }]
      }, {
        "id": 5,
        "name": "ISPSystem",
        "avatar": "/images/ISPSYSTEM.png",
        "link": "https://www.ispsystem.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 2,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:39:05.000Z",
        "updatedAt": "2022-04-05T06:47:24.000Z",
        "PartnerFiles": []
      }, {
        "id": 13,
        "name": "ICT",
        "avatar": "/images/ISTONLINE.png",
        "link": "https://ict-online.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 2,
        "hideToIndex": false,
        "createdAt": "2022-04-03T16:27:48.000Z",
        "updatedAt": "2022-04-05T06:47:36.000Z",
        "PartnerFiles": []
      }, {
        "id": 25,
        "name": "IVA Technologies",
        "avatar": "/images/Technologies (1).png",
        "link": "/partners/iva-technologies",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 2,
        "hideToIndex": false,
        "createdAt": "2022-04-13T13:46:24.000Z",
        "updatedAt": "2022-04-20T16:59:05.000Z",
        "PartnerFiles": [{
          "id": 2,
          "link": "https://drive.google.com/file/d/1HBKGB5eyFIuLNA6S5l6XRJjlIHcjxKjP/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T11:14:27.000Z",
          "updatedAt": "2022-04-17T11:22:31.000Z",
          "partner_id": 25
        }, {
          "id": 3,
          "link": "https://drive.google.com/file/d/1jPJ23AEp5z9Rlg5g8LVTeSf_eNSXcsch/view?usp=sharing",
          "name": "Буклет 1",
          "order": 2,
          "createdAt": "2022-04-17T11:23:50.000Z",
          "updatedAt": "2022-04-17T18:00:40.000Z",
          "partner_id": 25
        }, {
          "id": 4,
          "link": "https://drive.google.com/file/d/10F8vsCxlYbbHQabk-gdF9lAwFiVFlrFO/view?usp=sharing",
          "name": "Буклет 2",
          "order": 3,
          "createdAt": "2022-04-17T11:26:27.000Z",
          "updatedAt": "2022-04-17T18:00:39.000Z",
          "partner_id": 25
        }]
      }, {
        "id": 6,
        "name": "Softline We Know",
        "avatar": "/images/SOFTLINE.png",
        "link": "https://softline.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 3,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:39:39.000Z",
        "updatedAt": "2022-04-05T06:47:49.000Z",
        "PartnerFiles": [{
          "id": 11,
          "link": "https://drive.google.com/file/d/1qOby_LgAQZ5Q1rjRq-CzJNmqkpQGPBNR/view?usp=sharing",
          "name": "Буклет",
          "order": 1,
          "createdAt": "2022-04-17T11:43:14.000Z",
          "updatedAt": "2022-04-17T11:47:21.000Z",
          "partner_id": 6
        }]
      }, {
        "id": 7,
        "name": "MIG",
        "avatar": "/images/MOBILEGROUP.png",
        "link": "https://m-infogroup.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 4,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:40:09.000Z",
        "updatedAt": "2022-04-05T06:48:06.000Z",
        "PartnerFiles": [{
          "id": 8,
          "link": "https://drive.google.com/file/d/1TwnSnC95977Umpt1UYfNYill-J-BU3x7/view?usp=sharing",
          "name": "Буклет",
          "order": 1,
          "createdAt": "2022-04-17T11:34:50.000Z",
          "updatedAt": "2022-04-17T11:34:50.000Z",
          "partner_id": 7
        }]
      }, {
        "id": 19,
        "name": "commnews",
        "avatar": "/images/comnews.png",
        "link": "https://www.comnews.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 4,
        "hideToIndex": false,
        "createdAt": "2022-04-07T09:48:48.000Z",
        "updatedAt": "2022-04-07T09:49:26.000Z",
        "PartnerFiles": []
      }, {
        "id": 40,
        "name": "Астра",
        "avatar": "/images/astra.png",
        "link": "https://astralinux.ru/",
        "group": "Стратегические партнеры",
        "newPage": true,
        "order": 4,
        "hideToIndex": true,
        "createdAt": "2022-04-20T10:47:22.000Z",
        "updatedAt": "2022-05-11T16:30:54.000Z",
        "PartnerFiles": [{
          "id": 29,
          "link": "https://drive.google.com/file/d/1NfBEwXXSfrpYTKDib9hcWgSZGVIdjBPH/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-20T11:15:59.000Z",
          "updatedAt": "2022-04-20T11:15:59.000Z",
          "partner_id": 40
        }, {
          "id": 30,
          "link": "https://drive.google.com/file/d/1VVb2HB-X4iSe_zRzEdLjS8KHeFdwwiOj/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-20T11:16:20.000Z",
          "updatedAt": "2022-04-20T11:16:20.000Z",
          "partner_id": 40
        }]
      }, {
        "id": 8,
        "name": "Searchinform",
        "avatar": "/images/searchinform.png",
        "link": "https://searchinform.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:40:44.000Z",
        "updatedAt": "2022-04-05T06:48:33.000Z",
        "PartnerFiles": [{
          "id": 9,
          "link": "https://drive.google.com/file/d/1G711KOArVk4vwbBret3-bx4lIfu-yb1o/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T11:36:21.000Z",
          "updatedAt": "2022-04-17T11:36:21.000Z",
          "partner_id": 8
        }, {
          "id": 10,
          "link": "https://drive.google.com/file/d/1Wx0A80kCpcmkmtGJCQfQOwkvua_sMLbJ/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-17T11:36:54.000Z",
          "updatedAt": "2022-04-17T11:36:54.000Z",
          "partner_id": 8
        }]
      }, {
        "id": 31,
        "name": "cisoclub",
        "avatar": "/images/cisoclub.png",
        "link": "https://cisoclub.ru/",
        "group": "Информационные партнеры",
        "newPage": true,
        "order": 5,
        "hideToIndex": false,
        "createdAt": "2022-04-15T13:06:38.000Z",
        "updatedAt": "2022-04-17T21:02:27.000Z",
        "PartnerFiles": []
      }, {
        "id": 9,
        "name": "Инфотекс",
        "avatar": "/images/infotech.png",
        "link": "https://infotecs.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 6,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:41:24.000Z",
        "updatedAt": "2022-04-05T06:48:58.000Z",
        "PartnerFiles": [{
          "id": 6,
          "link": "https://drive.google.com/file/d/1qDDh-D3YrzrJZImG3nCTi3zw4-jl9lDW/view?usp=sharing",
          "name": "Буклет 1",
          "order": 1,
          "createdAt": "2022-04-17T11:31:12.000Z",
          "updatedAt": "2022-04-17T18:00:02.000Z",
          "partner_id": 9
        }, {
          "id": 7,
          "link": "https://drive.google.com/file/d/16GJW60A8yf3WNVu8mgIm5_hvNJ1vyRmO/view?usp=sharing",
          "name": "Буклет 2",
          "order": 2,
          "createdAt": "2022-04-17T11:31:59.000Z",
          "updatedAt": "2022-04-17T18:00:03.000Z",
          "partner_id": 9
        }]
      }, {
        "id": 10,
        "name": "РТУ МИРЭА",
        "avatar": "/images/MIREA.png",
        "link": "https://www.mirea.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 7,
        "hideToIndex": false,
        "createdAt": "2022-04-03T12:42:50.000Z",
        "updatedAt": "2022-04-05T06:49:08.000Z",
        "PartnerFiles": []
      }, {
        "id": 15,
        "name": "Форс",
        "avatar": "/images/FORS.png",
        "link": "https://edu.fors.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 8,
        "hideToIndex": false,
        "createdAt": "2022-04-04T10:50:15.000Z",
        "updatedAt": "2022-04-15T07:30:06.000Z",
        "PartnerFiles": [{
          "id": 17,
          "link": "https://drive.google.com/file/d/1rm8gvbsC8ldz7xphYZ6_GelpCAaY0_C_/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-17T17:50:48.000Z",
          "updatedAt": "2022-04-17T17:50:48.000Z",
          "partner_id": 15
        }]
      }, {
        "id": 16,
        "name": "Depo",
        "avatar": "/images/DEPO.png",
        "link": "https://www.depo.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 9,
        "hideToIndex": false,
        "createdAt": "2022-04-04T10:50:43.000Z",
        "updatedAt": "2022-04-05T06:49:30.000Z",
        "PartnerFiles": [{
          "id": 22,
          "link": "https://drive.google.com/file/d/1gglUbu0C7Fz2q7kQUeOenhil_eXnb3Pz/view?usp=sharing",
          "name": "Листовка АРМ",
          "order": 1,
          "createdAt": "2022-04-18T18:03:18.000Z",
          "updatedAt": "2022-04-18T18:03:18.000Z",
          "partner_id": 16
        }, {
          "id": 23,
          "link": "https://drive.google.com/file/d/1HDfL5Qu9jS9JkY2LMyYlnD9BduRnHwDc/view?usp=sharing",
          "name": "Листовка Ноутбук",
          "order": 2,
          "createdAt": "2022-04-18T18:03:46.000Z",
          "updatedAt": "2022-04-18T18:03:46.000Z",
          "partner_id": 16
        }, {
          "id": 24,
          "link": "https://drive.google.com/file/d/1TOdSKVWp1LA4cw7455S1aUg3NH9mQj6l/view?usp=sharing",
          "name": "Листовка Серверы",
          "order": 3,
          "createdAt": "2022-04-18T18:04:23.000Z",
          "updatedAt": "2022-04-18T18:04:23.000Z",
          "partner_id": 16
        }, {
          "id": 25,
          "link": "https://drive.google.com/file/d/1lgw9_GJW4r3XOC--SAjKkK00qTPikUd5/view?usp=sharing",
          "name": "Листовка СХД",
          "order": 4,
          "createdAt": "2022-04-18T18:04:43.000Z",
          "updatedAt": "2022-04-18T18:04:43.000Z",
          "partner_id": 16
        }]
      }, {
        "id": 17,
        "name": "КРОК",
        "avatar": "/images/krok.png",
        "link": "https://www.croc.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 10,
        "hideToIndex": false,
        "createdAt": "2022-04-07T09:17:02.000Z",
        "updatedAt": "2022-04-07T09:17:02.000Z",
        "PartnerFiles": [{
          "id": 20,
          "link": "https://drive.google.com/file/d/1rECPpR4CN6-zniEgV5x_RW1kG-v_4t-E/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-17T20:43:17.000Z",
          "updatedAt": "2022-04-17T20:43:17.000Z",
          "partner_id": 17
        }]
      }, {
        "id": 18,
        "name": "AXOFT",
        "avatar": "/images/AXOFT.png",
        "link": "https://axoft.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 11,
        "hideToIndex": false,
        "createdAt": "2022-04-07T09:17:52.000Z",
        "updatedAt": "2022-04-07T09:17:52.000Z",
        "PartnerFiles": [{
          "id": 13,
          "link": "https://drive.google.com/file/d/1oAigNhrpQ_hvjPQB7S8iboCtHtZ60gZz/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T17:46:18.000Z",
          "updatedAt": "2022-04-17T17:46:18.000Z",
          "partner_id": 18
        }, {
          "id": 14,
          "link": "https://drive.google.com/file/d/145wyayG-DkLTltXmoR2yX00WIBp8I5zF/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-17T17:46:49.000Z",
          "updatedAt": "2022-04-17T17:46:49.000Z",
          "partner_id": 18
        }]
      }, {
        "id": 20,
        "name": "step",
        "avatar": "/images/logo_step.png",
        "link": "https://step.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 14,
        "hideToIndex": false,
        "createdAt": "2022-04-08T07:34:02.000Z",
        "updatedAt": "2022-04-13T11:26:43.000Z",
        "PartnerFiles": []
      }, {
        "id": 21,
        "name": "Гравитон",
        "avatar": "/images/H_GRAVITON.png",
        "link": "https://graviton.3l.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 15,
        "hideToIndex": false,
        "createdAt": "2022-04-13T13:42:30.000Z",
        "updatedAt": "2022-04-13T13:42:30.000Z",
        "PartnerFiles": [{
          "id": 18,
          "link": "https://drive.google.com/file/d/1qP1LaYgtXmwEvFxN55Zbk8zn_U77cmii/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-17T17:52:26.000Z",
          "updatedAt": "2022-04-17T17:52:26.000Z",
          "partner_id": 21
        }]
      }, {
        "id": 22,
        "name": "Полиматика",
        "avatar": "/images/polymatica.png",
        "link": "https://www.polymatica.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 16,
        "hideToIndex": false,
        "createdAt": "2022-04-13T13:43:14.000Z",
        "updatedAt": "2022-04-13T13:43:14.000Z",
        "PartnerFiles": [{
          "id": 26,
          "link": "https://drive.google.com/file/d/19ap3TmP8jESDJmw-1ARzYTklZPFnoEGZ/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-19T20:25:16.000Z",
          "updatedAt": "2022-04-19T20:25:16.000Z",
          "partner_id": 22
        }, {
          "id": 27,
          "link": "https://drive.google.com/file/d/1wrbfhOjSBC4lOGbshZf3S7eeZLh6HtY7/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-19T20:25:38.000Z",
          "updatedAt": "2022-04-19T20:25:38.000Z",
          "partner_id": 22
        }]
      }, {
        "id": 23,
        "name": "S-Terra",
        "avatar": "/images/S-terra.png",
        "link": "https://www.s-terra.ru",
        "group": "Партнеры",
        "newPage": true,
        "order": 17,
        "hideToIndex": false,
        "createdAt": "2022-04-13T13:43:53.000Z",
        "updatedAt": "2022-04-13T13:59:33.000Z",
        "PartnerFiles": [{
          "id": 16,
          "link": "https://drive.google.com/file/d/1gk3PPrFd4DfXGEvmOWGTButlOHipADki/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T17:49:44.000Z",
          "updatedAt": "2022-04-17T17:49:44.000Z",
          "partner_id": 23
        }]
      }, {
        "id": 24,
        "name": "НГТУ НЭТИ",
        "avatar": "/images/NGTU.png",
        "link": "https://www.nstu.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 18,
        "hideToIndex": false,
        "createdAt": "2022-04-13T13:44:35.000Z",
        "updatedAt": "2022-04-13T21:43:28.000Z",
        "PartnerFiles": []
      }, {
        "id": 26,
        "name": "МИЭМ НИУ ВШЭ",
        "avatar": "/images/ВШЭ.png",
        "link": "https://www.hse.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 18,
        "hideToIndex": false,
        "createdAt": "2022-04-14T04:46:36.000Z",
        "updatedAt": "2022-04-14T04:46:36.000Z",
        "PartnerFiles": []
      }, {
        "id": 27,
        "name": "Softline Education",
        "avatar": "/images/soft_line_U.png",
        "link": "https://edu.softline.com/",
        "group": "Партнеры",
        "newPage": true,
        "order": 20,
        "hideToIndex": false,
        "createdAt": "2022-04-14T09:43:33.000Z",
        "updatedAt": "2022-04-14T12:58:24.000Z",
        "PartnerFiles": [{
          "id": 12,
          "link": "https://drive.google.com/file/d/1QOvRRTxvmo5fnrEIXRE0eNTASQrJMctD/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T17:44:38.000Z",
          "updatedAt": "2022-04-17T17:44:38.000Z",
          "partner_id": 27
        }]
      }, {
        "id": 28,
        "name": "Мерлион",
        "avatar": "/images/merlion.png",
        "link": "https://merlion.com/",
        "group": "Партнеры",
        "newPage": true,
        "order": 21,
        "hideToIndex": false,
        "createdAt": "2022-04-14T09:44:02.000Z",
        "updatedAt": "2022-04-14T12:43:36.000Z",
        "PartnerFiles": [{
          "id": 15,
          "link": "https://drive.google.com/file/d/1Rb6rTLqvmXBAz4SXQiM3p8vnUCvY7Zw3/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-17T17:48:36.000Z",
          "updatedAt": "2022-04-17T17:48:36.000Z",
          "partner_id": 28
        }]
      }, {
        "id": 29,
        "name": "my_office",
        "avatar": "/images/Мойофис.png",
        "link": "https://myoffice.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 22,
        "hideToIndex": false,
        "createdAt": "2022-04-15T13:05:42.000Z",
        "updatedAt": "2022-04-19T10:16:26.000Z",
        "PartnerFiles": [{
          "id": 21,
          "link": "https://drive.google.com/file/d/1x4y1CE7Hj-n7WnvTJu6Fqu_R6HMdJ9G5/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-17T20:44:48.000Z",
          "updatedAt": "2022-04-17T20:44:48.000Z",
          "partner_id": 29
        }]
      }, {
        "id": 30,
        "name": "mgu_BMK",
        "avatar": "/images/mgu_BMK.png",
        "link": "https://cs.msu.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 23,
        "hideToIndex": false,
        "createdAt": "2022-04-15T13:06:10.000Z",
        "updatedAt": "2022-04-16T11:17:08.000Z",
        "PartnerFiles": []
      }, {
        "id": 33,
        "name": "RuBackup",
        "avatar": "/images/rubackup.png",
        "link": "https://rubackup.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 23,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:43:21.000Z",
        "updatedAt": "2022-05-11T16:30:26.000Z",
        "PartnerFiles": [{
          "id": 31,
          "link": "https://drive.google.com/file/d/1HvyqjS4hVCUHHVmePTPHgt1lrWIFqFbu/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-20T11:18:26.000Z",
          "updatedAt": "2022-04-20T11:18:26.000Z",
          "partner_id": 33
        }]
      }, {
        "id": 34,
        "name": "Rupost",
        "avatar": "/images/rupost.png",
        "link": "http://rupost.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 24,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:43:48.000Z",
        "updatedAt": "2022-05-11T16:29:12.000Z",
        "PartnerFiles": []
      }, {
        "id": 35,
        "name": "termidesk",
        "avatar": "/images/termidesk.png",
        "link": "https://termidesk.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 25,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:44:17.000Z",
        "updatedAt": "2022-05-11T16:28:12.000Z",
        "PartnerFiles": [{
          "id": 32,
          "link": "https://drive.google.com/file/d/1pi9GlCABsMo1oEKkYet8kinzGNatvcnj/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-20T11:20:34.000Z",
          "updatedAt": "2022-04-20T11:20:34.000Z",
          "partner_id": 35
        }, {
          "id": 33,
          "link": "https://drive.google.com/file/d/1RmfSNn2OzXaWDgBaiigEBVeaDZrF3P5F/view?usp=sharing",
          "name": "Буклет",
          "order": 2,
          "createdAt": "2022-04-20T11:20:58.000Z",
          "updatedAt": "2022-04-20T11:20:58.000Z",
          "partner_id": 35
        }]
      }, {
        "id": 36,
        "name": "WP logo",
        "avatar": "/images/worksPad.png",
        "link": "https://www.workspad.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 26,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:44:53.000Z",
        "updatedAt": "2022-05-11T16:29:50.000Z",
        "PartnerFiles": [{
          "id": 34,
          "link": "https://drive.google.com/file/d/10Eu1RF2PG_Nju68SjbuqXUj2Va7bsUnE/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-20T11:22:50.000Z",
          "updatedAt": "2022-04-20T11:22:50.000Z",
          "partner_id": 36
        }]
      }, {
        "id": 37,
        "name": "Брест",
        "avatar": "/images/Брест.png",
        "link": "https://astralinux.ru/products/pk-brest/",
        "group": "Партнеры",
        "newPage": true,
        "order": 27,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:45:45.000Z",
        "updatedAt": "2022-05-13T14:54:19.000Z",
        "PartnerFiles": []
      }, {
        "id": 39,
        "name": "ALD Pro",
        "avatar": "/images/aldpro.png",
        "link": "https://astralinux.ru/products/ald-pro",
        "group": "Партнеры",
        "newPage": true,
        "order": 28,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:46:54.000Z",
        "updatedAt": "2022-05-13T14:57:43.000Z",
        "PartnerFiles": [{
          "id": 28,
          "link": "https://drive.google.com/file/d/1JjahcIOvVWJ-Da0aDH1ibvBsmDeoqvM9/view?usp=sharing",
          "name": "Листовка",
          "order": 1,
          "createdAt": "2022-04-20T11:14:00.000Z",
          "updatedAt": "2022-04-20T11:14:00.000Z",
          "partner_id": 39
        }]
      }, {
        "id": 41,
        "name": "Иннополис",
        "avatar": "/images/шттшзщдшы_гтшмукышен.png",
        "link": "https://innopolis.university/",
        "group": "Партнеры",
        "newPage": true,
        "order": 28,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:48:05.000Z",
        "updatedAt": "2022-05-13T14:57:02.000Z",
        "PartnerFiles": []
      }, {
        "id": 42,
        "name": "ITHUB",
        "avatar": "/images/ithubacademy.png",
        "link": "https://academy.ithub.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 29,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:48:32.000Z",
        "updatedAt": "2022-05-13T14:55:25.000Z",
        "PartnerFiles": []
      }, {
        "id": 43,
        "name": "БГМУ",
        "avatar": "/images/BGMU.png",
        "link": "https://bashgmu.ru/",
        "group": "Партнеры",
        "newPage": true,
        "order": 30,
        "hideToIndex": false,
        "createdAt": "2022-04-20T10:48:53.000Z",
        "updatedAt": "2022-05-13T14:58:25.000Z",
        "PartnerFiles": []
      }]
    }
  },
  computed: {
    getPartners() {
      if (!this.partners.length) {
        return this.partners
      }
      return _.sortBy(_.filter(this.partners, item => {
        item.PartnerFiles = _.sortBy(item.PartnerFiles, ['order'])
        return item.PartnerFiles.length
      }), ['order'])
    }
  },

}
</script>

<style scoped lang="scss">
.section_votes {
  padding: rem(30) 0;
}

.partner {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: rem(60) rem(30) rem(24);
  @include media(768) {
    padding: rem(15);
  }

  &__image {
    //padding: 0 rem(20);
    text-align: center;

    img {
      max-width: 100%;
      vertical-align: top;
    }
  }

  &__buttons {
    padding-top: rem(20);
  }

  &__button {
    cursor: pointer;

    svg {
      * {
        transition: all .25s;
      }
    }

    &:hover {
      svg {
        circle {
          fill: #59B980
        }
      }
    }

    &:first-child {
      margin-right: rem(5);
    }
  }

  &__file {
    padding-right: rem(18);

    a {
      font-size: rem(12);
      color: #52B5E0;
      text-decoration: none;
    }
  }
}
</style>
